import axios from 'axios';
import SockJS from 'sockjs-client';
import i18next from 'i18next';
import storage, {StorageKey as SK} from './storage';
import store from './store';
import {AuthPlatform, ErrCode, User} from './types';
import * as StompJS from "@stomp/stompjs";

class GuardianAPI {

    // 호스트를 env 파일을 수정해서 설정 가능합니다. local 서버에 연결할 때 .env.development 파일을 수정하면 됩니다
    HOST = (process?.env?.REACT_APP_BASE_URL !== null ? process.env.REACT_APP_BASE_URL : 'https://lab.fnsvalue.co.kr');
    MASTER_CLIENT_KEY = (process?.env?.REACT_APP_MASTER_CLIENT_KEY !== null ? process.env.REACT_APP_MASTER_CLIENT_KEY : '1daec78593a643e6b53ce9803ded5916');

    AUTH_PLATFORM = AuthPlatform.GuardianPortal;
    api = null;
    token = null;

    constructor() {
        this.token = storage.getSession(SK.ACCESS_TOKEN);

        this.api = axios.create({
            baseURL: this.HOST + '/api/v3',
            timeout: 1000 * 30,
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            headers: {}
        });
        this.api.defaults.withCredentials = true;
        this.api.interceptors.request.use(config => {
            config.metadata = {startTime: new Date()};
            if (this.token != null && this.token.length > 0) {
                config.headers['Authorization'] = this.token;
            }
            const method = config.method.toUpperCase();
            //console.log(method, '-', config.baseURL + config.url, '[' + this.token + ']', '[' + config.headers['Content-Type'] + ']', config.params);
            return config;
        });
        this.api.interceptors.response.use(response => {
            const rtime = new Date() - response.config.metadata.startTime;
            //console.log('ResponseTime (', rtime, 'ms) :', response.data);
            const {rtCode} = response.data;
            if (rtCode !== ErrCode.RT_SUCCESS) {
                response.data.rtMsg = this.getErrMsg(rtCode);
                throw response.data;
            }

            if (response.data.token != undefined) {
                this.token = response.data.token;
            }

            return response;
        });
    }

    getErrMsg = (errCode) => {
        return i18next.t('Error.' + errCode);   // 번역된 오류 메시지 가져오기
    };

    setToken = (token) => {
        this.token = token;
        storage.setSession(SK.ACCESS_TOKEN, token);
    };

    hasToken = () => {
        return this.token !== null;
    };

    /** 검색 query 작성하기
     * @param data query object
     * @param nonNull : boolean null 값 사용 여부
     **/
    encodeParams = (data, nonNull = false) => {
        return Object
            .keys(data)
            .map(key => {
                    if (!nonNull || data[key] !== null) {
                        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
                    }
                    return null;
                }
            )
            .filter(value => value !== null)
            .join('&');
    };

    getClientCodes = () => {
        return new Promise((resolve, reject) => {
            this.api.get('/clients')
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    /******* 인증 관련 API *******/

    getWebSocket = (clientKey, userKey, channelKey) => {
        const query = this.encodeParams({clientKey, userKey, channelKey});
        const ws = new SockJS(this.HOST + '/ws/v3/auth/status?' + query);
        return ws;
    };

    getQrWebSocket = (qrId) => {
        const query = this.encodeParams({qrId});
        const ws = new SockJS(this.HOST + '/ws/v3/qr/status?' + query);
        return ws;
    };

    getStompHOST = () => { return this.HOST.replace("http","ws"); }

    getStompWS = (clientKey, userKey, channelKey) => {
        const query = this.encodeParams( {clientKey, userKey, channelKey});
        const stomp = new StompJS.Client({
            brokerURL: this.getStompHOST() + '/ws/v3/app/websocket?' + query
            //debug: function (data){ console.log("debug => ", data); }
            , reconnectDelay: 500
        })
        stomp.activate();
        return stomp;
    }

    getStompQrWS = (qrId) => {
        const query = this.encodeParams({qrId});
        const stomp = new StompJS.Client({
            brokerURL: this.getStompHOST() + '/ws/v3/app/qr/websocket?' + query
            //debug: function (data){ console.log("debug => ", data); }
            , reconnectDelay: 500
        })
        stomp.activate();
        return stomp;
    }


    requestAuth = (clientKey, userKey, isOtpAuth = false) => {
        return new Promise((resolve, reject) => {
            this.api.post('/auth', {clientKey, userKey, isOtpAuth, authPlatform : this.AUTH_PLATFORM})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getAuthResult = (clientKey, userKey, channelKey, isQrAuth =false) => {
        return new Promise((resolve, reject) => {
            const query = this.encodeParams({clientKey, userKey, channelKey,isQrAuth});
            this.api.get('/auth?' + query)
                .then(res => {
                    this.setToken(res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    deleteAuth = (clientKey, userKey, qrId = null) => {
        return new Promise((resolve, reject) => {
            this.api.delete('/auth', {data: {clientKey: clientKey, userKey: userKey, qrId: qrId}})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getLoginQrUrl = (clientKey) => {
        const url = `/qr/generate?${this.encodeParams({clientKey, authPlatform: this.AUTH_PLATFORM})}`;
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getTotpVerify = (otpCode) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/totp/verify?${this.encodeParams({otpCode})}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    postTotpUserVerify = (clientKey, userKey, otpCode) => {
        return new Promise((resolve, reject) => {
            this.api.post('/totp/user/verify', {clientKey, userKey, otpCode, authPlatform: this.AUTH_PLATFORM})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getOtpVerify = (masterClientKey, otpCode) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/otp/verify?${this.encodeParams({clientKey: masterClientKey, otpCode})}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    postOtpUserVerify = (clientKey, userKey, otpCode) => {
        return new Promise((resolve, reject) => {
            this.api.post('/otp/user/verify', {clientKey, userKey, otpCode})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getMe = () => {
        return new Promise((resolve, reject) => {
            this.api.get('/me')
                .then(res => {
                    let user = new User(res.data.data);
                    store.setUser(user);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    /******* 클라이언트 관련 API *******/

    getClientList = (clientName, page = 0, size = 0) => {
        let url = '/admin/clients?' + this.encodeParams({page, size, clientName}, true);
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getClientDetail = (clientKey) => {
        return new Promise((resolve, reject) => {
            this.api.get('/admin/clients/' + clientKey)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    setClientModify = (clientKey, clientData) => {
        return new Promise((resolve, reject) => {
            this.api.put('/admin/clients/' + clientKey, clientData)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    setClient = (clientData) => {
        return new Promise((resolve, reject) => {
            this.api.post('/admin/clients', clientData)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getClientStatus = (clientKey) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/admin/clients/${clientKey}/status`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    /******* 문의 & 답변 관련 API *******/

    getQnaList = (params) => {
        const query = this.encodeParams(params);
        return new Promise((resolve, reject) => {
            this.api.get('/admin/qna?' + query)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getQnaDetail = (questionSeq) => {
        return new Promise((resolve, reject) => {
            this.api.get('/admin/qna/' + questionSeq)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    postQuestion = (params) => {
        return new Promise((resolve, reject) => {
            this.api.post('/admin/qna', params)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    putQuestion = (params) => {
        return new Promise((resolve, reject) => {
            this.api.put('/admin/qna/' + params.questionSeq, params)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    postQuestionAnswer = (params) => {
        return new Promise((resolve, reject) => {
            this.api.post('/admin/qna/' + params.questionSeq + '/answer', params)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    deleteQuestion = (questionSeq) => {
        return new Promise((resolve, reject) => {
            this.api.delete('/admin/qna/' + questionSeq)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    /******* 사용자 관련 API *******/

    getUserList = (clientKey, keyword, status, page = 0, size = 0, sort = null) => {
        const url = `/admin/clients/${clientKey}/users?${this.encodeParams({keyword: keyword !== null ? keyword.trim() : null, status: status, page, size, sort}, true)}`;
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getUserDetail = (clientKey, userKey) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/admin/clients/${clientKey}/users/${userKey}/`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getPermittedModifications = (clientKey, userKey) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/admin/clients/${clientKey}/users/${userKey}/permitted-modifications`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    changeAuthority = (clientKey, userKey, userType) => {
        return new Promise((resolve, reject) => {
            this.api.put(`/admin/clients/users/change-authority/`, {clientKey, userKey, userType})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    putUserInfoReset = (clientKey, userKey, category) => {
        return new Promise((resolve, reject) => {
            this.api.put(`/admin/clients/${clientKey}/users/${userKey}/reset`, {category})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getEvent = (clientKey, userKey, category, eventType, startDtStr, endDtStr, page = 0, size = 5) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/admin/users/${userKey}/event?${this.encodeParams({clientKey, category, eventType, startDate: startDtStr, endDate: endDtStr, page, size}, true)}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    /******* 로그 관련 API *******/

    getLogList = () => {
        let url = '/admin/config/log';
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    setLogModify = (logData) => {
        return new Promise((resolve, reject) => {
            this.api.put('/admin/config/log', logData)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    /******* 약관 관련 API *******/

    getAgreementList = (lang, useStatus, type, client, searchWord, page = 0, size = 0) => {
        const url = `/admin/agreements?${this.encodeParams({lang: lang, useStatus: useStatus, type: type, client: client, searchWord: searchWord, page, size}, true)}`;
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getAgreementDetail = (seq) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/admin/agreements/${seq}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    setAgreementModify = (seq, data) => {
        return new Promise((resolve, reject) => {
            this.api.put(`/admin/agreements/${seq}`, data)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    deleteAgreement = (seq) => {
        return new Promise((resolve, reject) => {
            this.api.delete(`/admin/agreements/${seq}`)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    postAgreement = (params) => {
        return new Promise((resolve, reject) => {
            this.api.post(`/admin/agreements`, params)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getClientAgreementList = (clientKey) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/clients/${clientKey}/agreements/available`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getClientAgreementApplyList = (clientKey) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/clients/${clientKey}/agreements`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    postClientAgreement = (clientKey, params) => {

        return new Promise((resolve, reject) => {
            this.api.post(`/admin/clients/${clientKey}/agreements`, params)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getCommonAgreementDetail = (clientKey, seq) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/clients/${clientKey}/agreements/${seq}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getMeClientCode = (type) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/me/clients?${this.encodeParams(type)}`)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    updateVerifyToken = () => {
        return new Promise((resolve, reject) => {
            this.api.put('/me/updateToken')
                .then(res => {

                    this.getMe()
                        .then(data => {
                            storage.setSession(SK.LOGIN_USER_INFO, {data});
                        })
                        .catch(err => {
                            console.log("error ==> ", err);
                        });

                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getAccessClientKey = (clientName) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/clientKey/${clientName}`)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getEnabledStatus = (clientKey, userKey) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/admin/clients/${clientKey}/users/${userKey}/enabled/status`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    putEnabledStatus = (clientKey, userKey, userStatus) => {
        return new Promise((resolve, reject) => {
            this.api.put(`/admin/clients/${clientKey}/users/${userKey}/enabled/status`, {userStatus})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getManagerList = (clientKey, keyword, type, status, page = 0, size = 0, sort = null) => {
        const url = `/admin/clients/${clientKey}/managers?${this.encodeParams({keyword: keyword !== null ? keyword.trim() : null, type, status, page, size, sort}, true)}`;
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    /****** 라이선스 관련 API ******/

    getLicensePage = (client, vendor, status, page = 0, size = 0) => {
        const url = `/admin/clients/license?${this.encodeParams({clientKey: client, vendorClientKey: vendor, status: status, page, size}, true)}`;
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getLicenseDetail = (seq) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/admin/clients/license/${seq}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    postLicense = (params) => {
        return new Promise((resolve, reject) => {
            this.api.post('/admin/clients/license', params)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    deleteLicense = (seq) => {
        return new Promise((resolve, reject) => {
            this.api.delete('/admin/clients/license', {data: {licenseSeq: seq}})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getLinkClient = (page = 0, size = 5) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/me/links?${this.encodeParams({page, size}, true)}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    deleteUnLink(clientKey) {
        return new Promise((resolve, reject) => {
            this.api.delete('/me/unlink', {data: {clientKey: clientKey}})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    deleteAdminUnLink(params) {
        return new Promise((resolve, reject) => {
            this.api.delete(`/admin/clients/${params.clientKey}/users/${params.userKey}/unlink`, {data: {...params}})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    /****** (대시보드 차트 데이터) Chart Stats API ******/

    getMyPlatformStats = (startDtStr = null, endDtStr = null) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/me/analysis/platform?${this.encodeParams({startDate: startDtStr, endDate: endDtStr}, true)}`)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getMyAuthHistoryStats = (timeUnit, viewCount) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/me/analysis/authHistory?${this.encodeParams({timeUnit: timeUnit, viewCount: viewCount}, true)}`)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    /****** 사용자 관리 API ******/

    getUserManagementPage = (keyword, status, page = 0, size = 0, sort = null) => {
        const url = `admin/management/users?${this.encodeParams({keyword: keyword !== null ? keyword.trim() : null, status: status, page, size, sort}, true)}`;
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getUserInfo = (userKey) => {
        const url = `admin/management/users/${userKey}/info`;
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    deleteUser = (userKey, isUserDelete = false, isDeviceDelete = false) => {
        const url = `admin/management/users/${userKey}/delete`;
        return new Promise((resolve, reject) => {
            this.api.delete(url, {data: {isUserDelete: isUserDelete, isDeviceDelete: isDeviceDelete}})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    postContactMail = (params) => {
        return new Promise((resolve, reject) => {
            this.api.post('/contact/mail', params)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    /****** 이력 (history) 관련 API ******/

    getAgreementHistory = (query, page = 0, size = 5, sort = 'REG_DT,DESC') => {
        return new Promise((resolve, reject) => {
            this.api.get(`/history/agreement?${this.encodeParams({...query, page: page, size: size, sort: sort}, true)}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getClientHistory = (query, page = 0, size = 5, sort = 'REG_DT,DESC') => {
        return new Promise((resolve, reject) => {
            this.api.get(`/history/client?${this.encodeParams({...query, page: page, size: size, sort: sort}, true)}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getQuestionHistory = (query, page = 0, size = 5, sort = 'REG_DT,DESC') => {
        return new Promise((resolve, reject) => {
            this.api.get(`/history/question?${this.encodeParams({...query, page: page, size: size, sort: sort}, true)}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getAllUserHistory = (query, page = 0, size = 5, sort = 'REG_DT,DESC') => {
        return new Promise((resolve, reject) => {
            this.api.get(`/history/user?${this.encodeParams({...query, page: page, size: size, sort: sort}, true)}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getMyEventHistory = (query, page = 0, size = 5, sort = 'REG_DT,DESC') => {
        const url = `/history/me?${this.encodeParams({...query, page: page, size: size, sort: sort}, true)}`;
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getUserEventHistory = (clientKey, eventUser, query, page = 0, size = 5, sort = 'REG_DT,DESC') => {
        return new Promise((resolve, reject) => {
            this.api.get(`/history/clients/${clientKey}/user/${eventUser}/?${this.encodeParams({...query, page: page, size: size, sort: sort}, true)}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getHistoryValueList = () => {
        return new Promise((resolve, reject) => {
            this.api.get(`/history/settings`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getEventHistoryDetail = (dataType, seq) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/history/${dataType}/${seq}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getMyAuthHistoryList = (query,  page = 0, size = 5, sort = 'REG_DT,DESC') => {
        return new Promise((resolve, reject) => {
            this.api.get(`/me/history?${this.encodeParams({...query, page: page, size: size, sort: sort}, true)}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getAllAuthHistory = (query,  page = 0, size = 5, sort = 'REG_DT,DESC') => {
        return new Promise((resolve, reject) => {
            this.api.get(`/history/auth?${this.encodeParams({...query, page: page, size: size, sort: sort}, true)}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    getUserAuthHistoryList = (clientKey, userKey, query,  page = 0, size = 5, sort = 'REG_DT,DESC') => {
        const url = `/admin/clients/${clientKey}/users/${userKey}/history?${this.encodeParams({...query, page: page, size: size, sort: sort}, true)}`;
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    getLicenseHistory  = (query, page = 0, size = 5, sort = 'REG_DT,DESC') => {
        return new Promise((resolve, reject) => {
            this.api.get(`/history/license?${this.encodeParams({...query, page: page, size: size, sort: sort}, true)}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    /****** 패치노트 관련 API ******/

    getPatchNoteList = ({ type, version, regUser, regDate, deployDt, keyword, clientKey }, page = 0, size = 0) => {
        const clientKeys = Array.isArray(clientKey) ? clientKey.filter(Boolean) : [clientKey].filter(Boolean);

        let clientKeyParams = '';
        const pageAndSizeParams = `page=${page}&size=${size}`;

        if (clientKeys.length > 0) {
            clientKeyParams = clientKeys.map(key => `clientKey=${encodeURIComponent(key)}`).join('&');
        }

        const url = `/patches?${this.encodeParams({
            patchType: type,
            version,
            regUserName: regUser,
            regDt: regDate,
            deployDt: deployDt,
            title: keyword,
        }, true)}&${pageAndSizeParams}${clientKeyParams ? `&${clientKeyParams}` : ''}`;

        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }



    getPatchNoteDetail = (seq) => {
        return new Promise((resolve, reject) => {
            this.api.get('/patches/' + seq)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    setPatchNoteModify = (seq, data) => {
        return new Promise((resolve, reject) => {
            this.api.put(`/patches/${seq}`, data)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    deletePatchNote = (seq) => {
        return new Promise((resolve, reject) => {
            this.api.delete(`/patches/${seq}`)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    postPatchNote = (params) => {
        return new Promise((resolve, reject) => {
            this.api.post(`/patches`, params)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }



}

export default new GuardianAPI();
